<template>
  <div class="g19-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-input
            type="text"
            v-model="searchModel.materialListCode"
            placeholder="子目号"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">查询</el-button>
          <el-button v-if="showAddBtn" type="primary" @click="handleAddClick">添加</el-button>
          <el-button v-if="showAddBtn" type="primary" @click="handleBatchDeleteClick">批量删除</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <my-el-table
      :data="g19List"
      :total="total"
      :page-index="pageIndex"
      :page-size="pageSize"
      :selection="true"
      keyFiled="g19Guid"
      :cell-class-name="cellClassName"
      @select-all-change="handleSelectAllChange"
      @selection-change="handleSelectionChange"
      @size-change="handlePageSizeChange"
      @current-change="handlePageIndexChange"
      >
      <el-table-column fixed="left" prop="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column fixed="left" prop="materialList.materialListName" width="260" header-align="left" align="left" label="工程或费用项目名称"></el-table-column>
      <el-table-column fixed="left" prop="materialList.unit" width="60" header-align="center" align="center" label="单位"></el-table-column>
      <el-table-column label="合同数量" prop="materialList.num" header-align="center">
      </el-table-column>
      <el-table-column label="本月计划" prop="g28.currentMonthNum" header-align="center">
      </el-table-column>
      <el-table-column
        width="120"
        prop="currentDateCompleteNum"
        header-align="center"
        :label-class-name="showEditBtn ? 'editable' : ''"
        align="center"
        label="本日完成">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <editable-table-cell
            @blur="handleBlur(scope.row, 0)"
            v-if="showEditBtn && !scope.row.isLock"
            v-model="scope.row.currentDateCompleteNum">
          </editable-table-cell>
          <span v-else>{{ scope.row.currentDateCompleteNum }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="currentMonthCompleteNum" width="120" header-align="center" align="center" label="本月完成"></el-table-column>
      <el-table-column prop="totalCompleteNum" width="120" header-align="center" align="center" label="累计完成"></el-table-column>
      <el-table-column v-if="showAddBtn" fixed="right" width="50" label="操作" header-align="center" align="center">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <el-tooltip class="item" effect="light" content="删除" :open-delay="500" placement="top">
            <i @click="handleDeleteClick(scope.row)" v-if="!scope.row.isLock" class="text-danger far fa-trash-alt operation-button"></i>
            <i v-else class="text-muted far fa-trash-alt operation-button"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </my-el-table>

    <my-dialog
      ref="myDialog"
      title="选择草图"
      size="xl"
      cancel-text="取消"
      ok-text="确定"
      @ok-click="handleOk"
      >
      <picture-index
        ref="pictureIndex"
        :selection="true"
        >
      </picture-index>
    </my-dialog>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import PictureIndex from '@/views/PictureManage/PictureIndex'
import MyElTable from '@/components/MyElTable/MyElTable'
import G19Model from '@/model/G19Model'
import g19Service from '@/services/g19Service'
import PageOperation from '@/mixins/PageOperation'
import PageInfo from '@/support/PageInfo'
import { mapGetters } from 'vuex'
import auth from '@/common/auth'
import config from '@/config'
import EditableTableCell from '@/views/ExaminationManage/Components/EditableTableCell'

export default {
  name: 'G19Index',
  mixins: [
    PageOperation
  ],
  components: {
    PictureIndex,
    MyElTable,
    MyDialog,
    EditableTableCell,
    MySearchBar
  },
  filters: {
  },
  computed: {
    userGuid () {
      return auth.getUserInfo().userGuid
    },
    userIdentityGuid () {
      return auth.getUserInfo().identityGuid
    },
    couldLock () {
      return config.applyIdentities.indexOf(auth.getUserInfo().identityGuid) === -1
    },
    firstYear () {
      // TODO
      return this.year === 2020
    },
    showAddBtn () {
      return this.approvalUserGuid === auth.getUserInfo().userGuid && config.applyIdentities.indexOf(this.userIdentityGuid) > -1
    },
    showEditBtn () {
      return this.approvalUserGuid === auth.getUserInfo().userGuid
    },
    dataColumns () {
      if (this.g19List.length) {
        return this.g19List[0].children
      }
      return []
    },
    ...mapGetters('planStatExamination', {
      planStatExaminationGuid: 'getPlanStatExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      year: 'getYear',
      approvalUserGuid: 'getApprovalUserGuid'
    })
  },
  data () {
    return {
      testValue: 1,
      total: 0,
      g19List: [],
      g19Model: new G19Model(),
      searchModel: {
        planStatExaminationGuid: '',
        materialListCode: ''
      },
      selectedValues: [],
      totalAmount: ''
    }
  },
  methods: {
    cellClassName ({ row, column }) {
      if (!row.empty && column.property === 'currentDateCompleteNum' && parseFloat(row.currentDateCompleteNum) < 0) {
        return 'warning'
      }
      if (!row.empty && column.property === 'totalCompleteNum' && parseFloat(row[column.property]) > parseFloat(row.materialList.num)) {
        return 'warning'
      }
      return ''
    },
    handleBatchDeleteClick () {
      if (!this.selectedValues.length) {
        this.$message({
          type: 'warning',
          message: '请先选择要操作的数据！'
        })
        return
      }

      g19Service.batchDelete(this.selectedValues.join(','))
        .then(res => {
          if (res.data.code === 1) {
            this._getG01List()
            this.$message({
              type: 'success',
              message: '删除成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: '操作失败'
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '操作失败'
          })
        })
    },
    classFilter (column) {
      if (column.identityGuid === this.userIdentityGuid && this.userGuid === this.approvalUserGuid) {
        return 'editable'
      }
      return ''
    },
    handleBatchPicture (flag) {
      if (!this.selectedValues.length) {
        this.$message({
          type: 'warning',
          message: '请先选择要操作的数据！'
        })
        return
      }

      if (flag) {
        // open image selection ui
        this.$refs.myDialog.open()
      } else {
        this.doBatchPicture('')
      }
    },
    handleOk () {
      if (!this.$refs.pictureIndex.getSelectedPicture()) {
        this.$message({
          type: 'warning',
          message: '请选择要绑定的草图！'
        })
        return
      }
      this.$refs.myDialog.close()
      this.doBatchPicture(this.$refs.pictureIndex.getSelectedPicture().pictureGuid)
    },
    doBatchPicture (pictureGuid) {
      g19Service.batchPicture(this.selectedValues, pictureGuid)
        .then(res => {
          if (res.data.code === 1) {
            this._getG19List()
            this.$message({
              type: 'success',
              message: (pictureGuid ? '绑定草图' : '删除草图') + '成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: (pictureGuid ? '绑定草图' : '删除草图') + '失败！'
          })
        })
    },
    handleBatchLock (isLocked) {
      if (!this.selectedValues.length) {
        this.$message({
          type: 'warning',
          message: '请先选择要操作的数据！'
        })
        return
      }

      g19Service.batchLock(this.planStatExaminationGuid, this.selectedValues, isLocked)
        .then(res => {
          if (res.data.code === 1) {
            this._getG19List()
            this.$message({
              type: 'success',
              message: (isLocked ? '锁定' : '解锁') + '成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: (isLocked ? '锁定' : '解锁') + '失败！'
          })
        })
    },
    handleBlur (row, type = 0) {
      if (type) {
        console.log('g19Identity', row)
        this._approval(row)
      } else {
        row.currentDateCompleteNum = parseFloat(row.currentDateCompleteNum)
        this.g19Model = new G19Model(row)
        this._edit()
      }
    },
    handleSearchClick () {
      this._getG19List()
    },
    handleAddClick () {
      this.$router.push('/g19')
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let _g19Model = new G19Model(row)
          g19Service.delete(_g19Model.resourceId)
            .then(res => {
              window.console.log(res)
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this._getG19List()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              window.console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleSelectionChange (newVal) {
      this.selectedValues = newVal
      console.log('selectedValues', this.selectedValues)
    },
    handleSelectAllChange (checkAll) {
      this.g19List.forEach(g19 => {
        g19.checked = checkAll
      })
    },
    _getG19List () {
      this.selectedValues = []
      this.searchModel.planStatExaminationGuid = this.planStatExaminationGuid
      g19Service.list(new PageInfo({ pageSize: this.pageSize, pageIndex: this.pageIndex, fieldOrder: '' }), this.searchModel)
        .then(res => {
          if (res.data.code === 1) {
            this.total = res.data.pageCount
            this.g19List = res.data.data.map(g19 => {
              g19.checked = false
              return g19
            })
            console.log('this.g19List', this.g19List)
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          window.console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _approval (g19Identity) {
      g19Service.approval(g19Identity.g19Guid, g19Identity.identityGuid, g19Identity.num, g19Identity.sortId, this.planStatExaminationGuid, this.periodName)
        .then(res => {
          if (res.data.code === 1) {
            this._getG19List()
            this.$message({
              type: 'success',
              message: '修改数量成功！'
            })
          } else {
            this._getG19List()
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this._getG19List()
          this.$message({
            type: 'error',
            message: '修改数量失败！'
          })
        })
    },
    _edit () {
      g19Service.edit(this.g19Model)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this._getG19List()
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this._getG19List()
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this._getG19List()
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          window.console.log(err.request.response)
        })
    }
  },
  created () {
    this._getG19List()
    this.refreshMethod = this._getG19List
  }
}
</script>

<style scoped lang="scss">
::v-deep ::-webkit-scrollbar {
  display: block;
}
::v-deep ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::v-deep ::-webkit-scrollbar-track {
  border-radius: 15px;
  width: 10px;
}
::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  box-shadow: inset 0 0 6px rgba(200, 200, 200, 0.3);
}
::v-deep ::-webkit-scrollbar-thumb:window-inactive {
  background: #eee;
}
::v-deep .editable {
  color: red;
}
::v-deep .warning {
  background-color: red;
}
</style>
